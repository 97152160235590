import GTMScript from "../components/GTM/GTMScript";
import "../styles/globals.css";
import Script from "next/script";

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout ?? ((page) => page)
  return getLayout(
    <>
      <GTMScript />
      <Script id="cookieState">
        {`
          function getCookie(name) {
            const value = "; " + document.cookie;
            const parts = value.split("; " + name + "=");
            if (parts.length === 2) return parts.pop().split(';').shift();
          }
          window.loginState = {};
          try {
            let tmp = decodeURIComponent(getCookie('user-data'));
            if (tmp && tmp != 'undefined') {
              window.loginState._userData = JSON.parse(tmp);
            }
          } catch (err) {
            console.log(err);
          }
        `}
      </Script>

      <Script id="adthrive-script" data-no-optimize="1" data-cfasync="false">
        {`
        (function(w, d) {
          w.adthrive = w.adthrive || {};
          w.adthrive.cmd = w.
          adthrive.cmd || [];
          w.adthrive.plugin = 'adthrive-ads-manual';
          w.adthrive.host = 'ads.adthrive.com';var s = d.createElement('script');
          s.async = true;
          s.referrerpolicy='no-referrer-when-downgrade';
          s.src = 'https://' + w.adthrive.host + '/sites/61575e8e934c48ea554b3caa/ads.min.js?referrer=' + w.encodeURIComponent(w.location.href) + '&cb=' + (Math.floor(Math.random() * 100) + 1);
          var n = d.getElementsByTagName('script')[0];
          n.parentNode.insertBefore(s, n);
        })(window, document);
        `}
      </Script>


      

      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
